import { GetServerSidePropsContext } from 'next'
import { useLocale, useTranslations } from 'next-intl'
import Image from 'next/image'
import Link from 'next/link'

import { SearchSnippets } from '@/widgets'
import { loadUserAndTranslates } from 'src/shared/load-user-and-translates'

export default function Home() {
  const t = useTranslations()
  const locale = useLocale()

  return (
    <main className="container mx-auto flex  flex-auto flex-col justify-center bg-white px-4">
      <div className="mt-2 flex w-full flex-col lg:w-2/3 xl:w-4/6" itemScope itemType="https://schema.org/WebSite">
        <h1 className="mb-6 font-stem text-3xl sm:text-3xl md:text-5xl">{t('main_title')}</h1>
        <h2 className="text-base">{t('description_home')}</h2>
        <SearchSnippets placeholders={[t('main_search_placeholder'), 'test message', 'hello']} />
        <ul className="col-span-full mt-2 flex h-auto flex-col gap-y-3 md:w-[750px] md:flex-row md:gap-x-3 md:gap-y-0">
          <li className="rounded-xl p-2 text-sm">{t(`try_select_of_search_example`)}:</li>
          <li className="rounded-xl bg-gray-100 p-2 text-sm">
            <Link locale={locale} href='/search/kz/АО "Казпочта"' rel="nofollow">
              АО &quot;Казпочта&quot;
            </Link>
          </li>
          <li className="rounded-xl bg-gray-100 p-2 text-sm">
            <Link locale={locale} href="/search/kz/Есекеев Куанышбек Бакытбекович" rel="nofollow">
              Есекеев Куанышбек Бакытбекович
            </Link>
          </li>
          <li className="rounded-xl bg-gray-100 p-2 text-sm">
            <Link locale={locale} href="/search/kz/+77272503020" rel="nofollow">
              +77272503020
            </Link>
          </li>
        </ul>

        <ul className="mt-6 flex h-auto w-full flex-col justify-between md:flex-row ">
          <li className="flex flex-col items-center space-y-1 md:w-1/3 md:items-start">
            <div className="md:w-1/3">
              <Image
                src="/images/tools-dashboard.svg"
                alt="Risk Assessment"
                width={28}
                height={28}
                className="self-start"
              />
            </div>
            <p className="font-stem text-lg font-medium">{t('risk_assessment')}</p>
            <p className="font-sans text-sm">{t('risk_assessment_descr')}</p>
          </li>
          <li className="flex flex-col items-center space-y-1 md:w-1/3 md:items-start">
            <div className="md:w-1/3">
              <Image
                src="/images/inbox-down.svg"
                alt="Reports and statements"
                width={28}
                height={28}
                className="self-start"
              />
            </div>
            <p className="font-stem text-lg font-medium">{t('reports_and_statements')}</p>
            <p className="font-sans text-sm">{t('reports_and_statements_descr')}</p>
          </li>{' '}
          <li className="flex flex-col items-center space-y-1 md:w-1/3 md:items-start">
            <div className="md:w-1/3">
              <Image src="/images/user-check.svg" alt="User checks" width={28} height={28} className="self-start" />
            </div>
            <p className="font-stem text-lg font-medium">{t('checking_the_manual')}</p>
            <p className="font-sans text-sm">{t('checking_the_manual_descr')}</p>
          </li>
        </ul>
      </div>
    </main>
  )
}

export async function getServerSideProps(ctx: GetServerSidePropsContext) {
  const { values, messages } = await loadUserAndTranslates(ctx)

  return {
    props: {
      messages,
      values,
    },
  }
}
